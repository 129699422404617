<template>
  <!-- 意见反馈 -->
  <div>
    <!-- 搜索区域 -->
    <h4 class="giter">意见反馈</h4>
    <div class="form">
      <!-- 状态查询 -->
      <div class="box">
        <p>按状态查询:</p>
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box2">
        <el-button
          type="primary"
          icon="el-icon-search"
          style="height: 40px; margin-top: 40px"
          >查询</el-button
        >
      </div>
    </div>
    <!-- 表单区域 -->
    <div style="margin-top: 30px">
      <el-table :data="data" border style="width: 100%">
        <el-table-column prop="adminTel" label="手机号" width="" align="center">
        </el-table-column>
        <el-table-column prop="userName" label="姓名" width="" align="center">
        </el-table-column>
        <el-table-column prop="content" label="问题" width="200" align="center">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="提交时间"
          width=""
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column prop="shebie" label="设备" width="" align="center">
        </el-table-column> -->
        <el-table-column prop="version" label="系统" width="" align="center">
        </el-table-column>
        <el-table-column
          prop="version"
          label="客户端版本"
          width=""
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="backTime"
          label="操作时间"
          width=""
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="caozuoName"
          label="操作人"
          width=""
          align="center"
        >
        </el-table-column>
        <el-table-column prop="status" label="状态" width="" align="center">
        </el-table-column>
        <el-table-column
          prop="back"
          label="回复内容"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleDelete(scope.$index, scope.row.id)"
              >回复</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页选项 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 50, 100]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="100"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: "",
      value: "",
      form: {
        phone: "",
        name: "",
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      data: [],
      currentPage4: 4,
    };
  },
  created() {
    this.onNesClassification();
  },
  methods: {
    onNesClassification() {
      this.axios
        .get("/admin/opinion/findAll", {})
        .then((res) => {
          this.data = res.data.data.records;
          console.log(this.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    on() {
      console.log(123);
    },
    // 重置
    restForm() {
      this.form = {
        phone: "",
        name: "",
      };
    },
    // 回复
    handleDelete(index, id) {
      this.userId = id;
      console.log(this.userId);
      this.open();
    },
    onNesClass() {
      this.axios
        .put("/admin/opinion/backOpinion", {
          id: this.userId,
          back: this.value,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 提示库框
    open() {
      this.$prompt("回复内容", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.value = value;
          console.log(this.value, "=====");
          this.onNesClass(),
            this.$message({
              type: "success",
              message: "回复成功",
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消回复",
          });
        });
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.giter {
  color: #409eff;
}
.form {
  margin-left: 40px;
  display: flex;
}
.box2 {
  margin-top: 13px;
  width: 85px;
  height: 85px;
}
.box {
  margin: 0px;
  margin-right: 20px;
}
.pagination {
  padding: 60px 0 0 110px;
}
</style>
